import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'castTo',
  pure: true,
})
export class CastToPipe implements PipeTransform {
  public transform<T>(value: any, typeOfClass: new (...args: any[]) => T): T {
    return value as T;
  }
}
