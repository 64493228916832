import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-button-formly',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonTypeComponent extends FieldType {
  public FormControl: typeof UntypedFormControl = UntypedFormControl;
}
