import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Observable } from 'rxjs';
import { unwrapResult } from '@core/operators/unwrap-result.operator';
import { ProposedLead } from '../models/proposed-lead.model';

@Injectable({
  providedIn: 'root',
})
export class ProposedLeadService extends ApiService<ProposedLead> {
  protected endpoint = 'proposed-lead';

  public getProposalStatus(
    token: string,
    status: string
  ): Observable<ProposedLead> {
    const url = `/${this.endpoint}/${token}/${status}`;

    return this.apiHttp.put(url, {}).pipe(unwrapResult());
  }
}
