<app-page-layout [title]="title">
  <app-back-to-overview backButton
                        *ngIf="backRouteLink"
                        [url]="backRouteLink">
  </app-back-to-overview>

  <ng-container buttons>
    <ng-content select="[additionalButtons]"></ng-content>

    <ng-container
      *ngIf="formType === FormType.EDIT && (formState === FormState.LOADING || formState === FormState.DISPLAYING); else saveButtonTemplate">
      <div class="d-flex flex-column flex-md-row button-container">
        <ng-content select="[editButtons]"></ng-content>

        <cap-button *ngIf="!hideEditButton"
                    [label]="'button.edit' | translate: { entity: entity }"
                    class="edit-button"
                    styleClass="primary h-100"
                    icon="fa fa-pencil-alt"
                    (onClick)="onEditClick()">
        </cap-button>
      </div>
    </ng-container>

    <ng-template #saveButtonTemplate>
      <cap-button [label]="'button.cancel' | translate"
                  styleClass="secondary mr-2 my-1 h-100"
                  (onClick)="onCancelClick()">
      </cap-button>

      <cap-button [label]="'button.submit' | translate"
                  [icon]="formState === FormState.SUBMITTING ? 'fas fa-spinner fa-pulse' : 'fa fa-check'"
                  [disabled]="formState === FormState.SUBMITTING"
                  styleClass="success my-1 h-100"
                  type="submit"
                  (onClick)="onSubmitClick()">
      </cap-button>
    </ng-template>
  </ng-container>

  <ng-content></ng-content>
</app-page-layout>
