import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-checkbox-formly',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
})
export class CheckboxTypeComponent extends FieldType {
  public FormControl: typeof UntypedFormControl = UntypedFormControl;
}
