import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { BaseDetailComponent as CompleteBaseDetailComponent } from '@capturum/shared';
import { UntypedFormGroup } from '@angular/forms';
import { combineLatest, Subject, Subscription } from 'rxjs';
import { FormState } from '@core/enums/form-state.enum';
import { FormStateService } from '@core/services/form-state.service';
import { TranslateService } from '@ngx-translate/core';
import { first } from 'rxjs/operators';
import { FormType } from '@core/enums/form-type.enum';
import { FormUtils } from '@core/utils/form.utils';
import { Entity } from '@core/enums/entity.enum';
import { FormlyFieldConfig } from '@ngx-formly/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '@capturum/api';

@Component({
  template: '',
})
// tslint:disable-next-line:component-class-suffix
export class BaseDetailClass<T>
  extends CompleteBaseDetailComponent<T>
  implements OnInit, OnDestroy
{
  public pageTitle: string;
  public entity: Entity;
  public initialValue: any = {};

  protected _subscription: Subscription = new Subscription();
  protected _destroy$: Subject<void> = new Subject<void>();

  protected readonly formStateService: FormStateService;

  public constructor(
    public injector: Injector,
    public apiService: ApiService<T>
  ) {
    super(injector, injector.get(TranslateService));

    this.formStateService = injector.get(FormStateService);
    this.route = injector.get(ActivatedRoute);
  }

  public ngOnInit(): void {
    this.createForm();
    this.handleFetching();
    this.handleStateChanges();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();

    this._subscription.unsubscribe();
    this._destroy$.next(null);
  }

  public createForm(): void {
    this.formGroup = new UntypedFormGroup({});
  }

  /**
   * Overwrite the default submit method of the Complete baseDetail to be able to submit without altering this.model.
   */
  public submit(): void {
    const payload = this.transformModelBeforeSubmit();

    this.handleSubmit(payload);
  }

  /**
   * Return payload used when submitting the POST request.
   *
   * @protected
   */
  protected transformModelBeforeSubmit(): any {
    return this.model;
  }

  /**
   * Handle submit request using the method: transformModelBeforeSubmit() to generate the payload used.
   *
   * @param payload
   * @protected
   */
  protected handleSubmit(payload: any): void {
    if (this.formGroup.valid) {
      const saveFunction = this.isEdit
        ? this.apiService.update(payload, { include: this.includes })
        : this.apiService.create(payload, { include: this.includes });

      saveFunction.subscribe({
        next: (response) => {
          if (this.saveResourceCallback) {
            this.saveResourceCallback(response);
          }

          this.handleSuccessfullSubmit();
          this.formStateService.triggerFormStateChange(FormState.DISPLAYING);
        },
        error: () => {
          this.formStateService.triggerFormStateChange(FormState.INTERACTING);
        },
      });
    } else {
      this.formStateService.triggerFormStateChange(FormState.INTERACTING);
      FormUtils.markAsTouched(this.formGroup);
    }
  }

  protected handleFetching(): void {
    combineLatest([this.route.data, this.route.params])
      .pipe(first())
      .subscribe(([routerData, { id, name }]) => {
        this.formStateService.triggerFormTypeChange(routerData.mode);

        this.isEdit = routerData.mode === FormType.EDIT;

        this.setTranslations(decodeURIComponent(name));

        if (this.isEdit) {
          this.getResource(id);
        } else {
          this.model = this.getResourceCallback(null);
        }
      });
  }

  protected getResourceCallback(response: any): T {
    const transformedResponse = this.transformDefaultModelValues(response);

    this.fields = this.generateFields(transformedResponse);

    this.stopLoading();

    return transformedResponse;
  }

  protected transformDefaultModelValues(response: any): any {
    return response;
  }

  protected generateFields(response: any): FormlyFieldConfig[] {
    return [];
  }

  protected stopLoading(): void {
    this.formStateService.triggerFormStateChange(
      this.isEdit ? FormState.DISPLAYING : FormState.INTERACTING
    );
  }

  protected setTranslations(name: string): void {
    this.modelTranslation = this.translateService.instant(
      `LeadPortal.entity.${this.entity}`
    );

    this.pageTitle = this.getPageTitle(name);

    this.saveMessage = this.translateService.instant(
      `LeadPortal.form.${this.isEdit ? 'edit' : 'add'}.success`,
      {
        name: this.modelTranslation,
      }
    );
  }

  protected getPageTitle(name: string): string {
    return this.translateService.instant(
      `LeadPortal.form.${this.isEdit ? 'edit' : 'add'}.title`,
      {
        entity: this.modelTranslation,
        name,
      }
    );
  }

  protected handleStateChanges(): void {
    this._subscription.add(
      this.formStateService.onFormStateChange.subscribe((formState) => {
        switch (formState) {
          case FormState.SUBMITTING:
            this.submit();
            break;
          case FormState.CANCELLING:
            this.onCancel();
            break;
          default:
            break;
        }
      })
    );
  }

  protected handleSuccessfullSubmit(): void {
    this.toastService.success(this.modelTranslation, this.saveMessage);

    this.onCancel();
  }
}
