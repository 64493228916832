import { LoginService as BlueprintLoginService } from '@capturum/login';

export class LGELoginService extends BlueprintLoginService {
  public onLoginSuccess(response: any): void {
    const isTwoFactorResponse = response?.hasOwnProperty('method');

    if (response && isTwoFactorResponse) {
      // Let AuthService.onSuccessfulLogin() handle the redirect
    } else if (response && !isTwoFactorResponse) {
      this.router.navigate(['/dashboard'], { queryParamsHandling: 'preserve' });
    }
  }
}
