<div class="container">
  <app-page-header [title]="title">
    <ng-container backButton>
      <ng-content select="[backButton]"></ng-content>
    </ng-container>

    <ng-container buttons>
      <ng-content select="[buttons]"></ng-content>
    </ng-container>
  </app-page-header>

  <hr *ngIf="showDivider" class="my-3" />

  <div class="row px-5">
    <div class="col p-0">
      <ng-content></ng-content>
    </div>
  </div>
</div>
