import { Observable } from 'rxjs';
import { ApiIndexResult } from '@capturum/api';
import { filter } from 'rxjs/operators';

/**
 * Use to get the data object stored in an index() response.
 *
 * Example:
 *    this.someApiService.index().pipe(
 *       unwrapResults<Entity>(),
 *    ).subscribe((items: Entity[]) => {})
 *
 * @param keys: A single key or array of keys that you want returned.
 */
export const unwrapResults = <T = any>(keys: string | string[] = 'data') => {
  return (source): Observable<T[]> => {
    return new Observable((subscriber) => {
      source.pipe(filter(Boolean)).subscribe({
        next(response: ApiIndexResult<T>): void {
          const value = Array.isArray(keys)
            ? keys.map((key) => response[key])
            : response[keys];

          subscriber.next(value);
        },
        error(error: any): void {
          subscriber.error(error);
        },
        complete(): void {
          subscriber.complete();
        },
      });
    });
  };
};

/**
 * Use to get the data object stored in a get() response.
 *
 * Example:
 *    this.someApiService.index().pipe(
 *       unwrapResult<Entity>(),
 *    ).subscribe((items: Entity[]) => {})
 *
 * @param keys: A single key or array of keys that you want returned.
 */
export const unwrapResult = <T = any>(keys: string | string[] = 'data') => {
  return (source): Observable<T> => {
    return new Observable((subscriber) => {
      source.pipe(filter(Boolean)).subscribe({
        next(response: ApiIndexResult<T>): void {
          const value = Array.isArray(keys)
            ? keys.map((key) => response[key])
            : response[keys];

          subscriber.next(value);
        },
        error(error: any): void {
          subscriber.error(error);
        },
        complete(): void {
          subscriber.complete();
        },
      });
    });
  };
};
