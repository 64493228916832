import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { LegendItem } from '@features/dashboard/models/legend-item.model';
import { LeadStatusesOverview } from '@features/dashboard/models/lead-statuses-overview.model';
import { LeadStatusConfigData } from '@features/dashboard/models/lead-status-config-data.model';
import { DashboardService } from '@features/dashboard/services/dashboard.service';
import { TranslateService } from '@ngx-translate/core';
import { AppRoutes } from '@core/enums/routes.enum';
import { UIChart } from 'primeng/chart';
import { BaseFilterService } from '@shared/modules/filters/services/base-filter.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FilterTypeConverterUtil } from '@shared/modules/filters/utils/filter-type-converter.util';
import { Subject } from 'rxjs';
import { User } from '../../../user/models/user.model';
import { AuthService } from '@core/services/auth.service';
import { Tenant } from '@core/enums/tenant.enum';
import { LeadStatus } from '../../../leads/models/lead-status.enum';

@Component({
  selector: 'app-leads-statuses-chart',
  templateUrl: './leads-statuses-chart.component.html',
  styleUrls: ['./leads-statuses-chart.component.scss'],
})
export class LeadsStatusesChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart')
  public UIChart: UIChart;

  public data: any;
  public chartOptions: any;
  public leadStatuses: LeadStatusesOverview;
  public leadStatusesConfig: LeadStatusConfigData;
  public AppRoutes = AppRoutes;
  public legendItems: LegendItem[];

  private destroy$ = new Subject();

  private tenantColorMap = {
    [Tenant.HEATPUMPS]: {
      [LeadStatus.Allocated]: '#800031',
      [LeadStatus.Planned]: '#b40f45',
      [LeadStatus.Quoted]: '#d64750',
      [LeadStatus.Approved]: '#f4725a',
      [LeadStatus.Installed]: '#fca57b',
      [LeadStatus.Reviewed]: '#b40f45',
      [LeadStatus.Dropped]: '#d0472e',
      [LeadStatus.Check]: '#f33f1c',
      [LeadStatus.Proposed]: '#8a2115',
    },
    [Tenant.AIRCONDITIONING]: {
      [LeadStatus.Allocated]: '#036273',
      [LeadStatus.Planned]: '#0096b4',
      [LeadStatus.Quoted]: '#0dccd9',
      [LeadStatus.Approved]: '#189eb6',
      [LeadStatus.Installed]: '#40ffef',
      [LeadStatus.Reviewed]: '#009396',
      [LeadStatus.Dropped]: '#00d7ff',
      [LeadStatus.Check]: '#8be3ee',
      [LeadStatus.Proposed]: '#04d0c4',
    },
  };

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly translateService: TranslateService,
    private readonly filterService: BaseFilterService,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.filterService
      .onFilterChange()
      .pipe(
        switchMap((filters) => {
          return this.dashboardService.getLeadStatusData({
            filters: FilterTypeConverterUtil.toFilterOptions(filters),
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((leadStatuses) => {
        this.leadStatuses = leadStatuses;

        this.initializeChart();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public initializeChart(): void {
    const currentUser: User = this.authService.getUser();

    this.leadStatusesConfig = {
      [this.translateService.instant('LeadPortal.lead.status.allocated.title')]:
        {
          nrOfLeads: this.leadStatuses?.status?.allocated?.count,
          percentage: this.leadStatuses?.status?.allocated?.percentage,
          backgroundColor: this.getBackgroundColorByTenantAndStatus(
            currentUser?.tenant_name,
            LeadStatus.Allocated
          ),
        },
      [this.translateService.instant('LeadPortal.lead.status.planned.title')]: {
        nrOfLeads: this.leadStatuses?.status?.planned?.count,
        percentage: this.leadStatuses?.status?.planned?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndStatus(
          currentUser?.tenant_name,
          LeadStatus.Planned
        ),
      },
      [this.translateService.instant('LeadPortal.lead.status.quoted.title')]: {
        nrOfLeads: this.leadStatuses?.status?.quoted?.count,
        percentage: this.leadStatuses?.status?.quoted?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndStatus(
          currentUser?.tenant_name,
          LeadStatus.Quoted
        ),
      },
      [this.translateService.instant('LeadPortal.lead.status.approved.title')]:
        {
          nrOfLeads: this.leadStatuses?.status?.approved?.count,
          percentage: this.leadStatuses?.status?.approved?.percentage,
          backgroundColor: this.getBackgroundColorByTenantAndStatus(
            currentUser?.tenant_name,
            LeadStatus.Approved
          ),
        },
      [this.translateService.instant('LeadPortal.lead.status.installed.title')]:
        {
          nrOfLeads: this.leadStatuses?.status?.installed?.count,
          percentage: this.leadStatuses?.status?.installed?.percentage,
          backgroundColor: this.getBackgroundColorByTenantAndStatus(
            currentUser?.tenant_name,
            LeadStatus.Installed
          ),
        },
      [this.translateService.instant('LeadPortal.lead.status.reviewed.title')]:
        {
          nrOfLeads: this.leadStatuses?.status?.reviewed?.count,
          percentage: this.leadStatuses?.status?.reviewed?.percentage,
          backgroundColor: this.getBackgroundColorByTenantAndStatus(
            currentUser?.tenant_name,
            LeadStatus.Reviewed
          ),
        },
      [this.translateService.instant('LeadPortal.lead.status.dropped.title')]: {
        nrOfLeads: this.leadStatuses?.status?.dropped?.count,
        percentage: this.leadStatuses?.status?.dropped?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndStatus(
          currentUser?.tenant_name,
          LeadStatus.Dropped
        ),
      },
      [this.translateService.instant('LeadPortal.base-data.lead_status_check')]:
        {
          nrOfLeads: this.leadStatuses?.status?.check?.count,
          percentage: this.leadStatuses?.status?.check?.percentage,
          backgroundColor: this.getBackgroundColorByTenantAndStatus(
            currentUser?.tenant_name,
            LeadStatus.Check
          ),
        },
      [this.translateService.instant(
        'LeadPortal.base-data.lead_status_proposed'
      )]: {
        nrOfLeads: this.leadStatuses?.status?.proposed?.count,
        percentage: this.leadStatuses?.status?.proposed?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndStatus(
          currentUser?.tenant_name,
          LeadStatus.Proposed
        ),
      },
    };

    this.legendItems = Object.entries(this.leadStatusesConfig).map(
      ([key, value]) => {
        return {
          backgroundColor: value.backgroundColor,
          nrOfLeads: value.nrOfLeads,
          name: key,
        };
      }
    );

    this.data = {
      labels: [...Object.keys(this.leadStatusesConfig)],
      legend: {
        position: 'right',
        align: 'middle',
      },
      datasets: [
        {
          data: this.legendItems.map((item) => {
            return item.nrOfLeads;
          }),
          backgroundColor: this.legendItems.map((item) => {
            return item.backgroundColor;
          }),
          ...this.leadStatusesConfig,
        },
      ],
    };

    this.chartOptions = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: ({ tooltip }) => {
            const chart = this.UIChart.chart;

            const tooltipElRef = this.getOrCreateTooltip();

            if (tooltip.opacity === 0) {
              tooltipElRef.style.opacity = '0';

              return;
            }

            tooltipElRef.classList.remove('above', 'below', 'no-transform');
            tooltipElRef.classList.add('center');

            tooltipElRef.innerHTML = this.getTooltipInnerHtml(tooltip);

            this.setTooltipCustomPosition(tooltipElRef, tooltip, chart);
          },
        },
      },
      responsive: false,
    };
  }

  private getBackgroundColorByTenantAndStatus(
    tenantName: Tenant,
    status: LeadStatus
  ): string {
    const tenantSetting = this.tenantColorMap[tenantName];

    return tenantSetting?.[status];
  }

  private getOrCreateTooltip(): HTMLElement {
    let tooltipElRef = document.getElementById('chartjs-tooltip');

    if (!tooltipElRef) {
      tooltipElRef = document.createElement('div');
      tooltipElRef.id = 'chartjs-tooltip';
      tooltipElRef.style.position = 'absolute';
      tooltipElRef.style.pointerEvents = 'none';

      const table = document.createElement('table');

      table.style.margin = '0px';

      tooltipElRef.appendChild(table);
      document.body.appendChild(tooltipElRef);
    }

    return tooltipElRef;
  }

  private getTooltipInnerHtml(tooltip: any): string {
    const title = tooltip?.body[0]?.lines[0].split(':')[0];

    const { nrOfLeads, percentage }: { nrOfLeads: number; percentage: number } =
      this.leadStatusesConfig[title];

    if (!nrOfLeads || !percentage) {
      return '';
    }

    const nrOfLeadsLabel = this.translateService.instant(
      'LeadPortal.dashboard.widget.lead-status.number-of-leads'
    );
    const percentageLabel = this.translateService.instant(
      'LeadPortal.dashboard.widget.lead-status.percentage'
    );

    return `
        <div class="tooltip-chartjs">
          <span class="title">${title}</span>
          <div class="body">
            <div class="info lead">
              <span>${nrOfLeadsLabel}</span>
              <span>${nrOfLeads}</span>
            </div>
            <div class="info percentage">
              <span>${percentageLabel}</span>
              <span>${percentage.toFixed(2)}%</span>
            </div>
          </div>
        </div>
    `;
  }

  private setTooltipCustomPosition(
    tooltipElRef: HTMLElement,
    tooltip: any,
    chart: any
  ): void {
    const position = chart.canvas.getBoundingClientRect();

    tooltipElRef.style.left =
      position.left + window.pageXOffset + tooltip.caretX - 100 + 'px';
    tooltipElRef.style.top =
      position.top + window.pageYOffset + tooltip.caretY + 'px';
    tooltipElRef.style.opacity = '1';
    tooltipElRef.style.fontSize = '14px';
    tooltipElRef.style.fontFamily = 'LGEITextTTF';
  }
}
