export enum BaseDataKey {
  LEAD_STATUS = 'lead_status',
  MAIN_PRODUCT = 'main_product',
  ADDITIONAL_PRODUCT = 'additional_product',
  LEAD_TYPE = 'lead_type',
  INSTALLER_CATEGORY = 'installer_category',
  LEAD_DROP_REASONS = 'lead_drop_reasons',
  COUNTRY = 'country',
  INSTALLER_STATUS = 'installer_status',
}
