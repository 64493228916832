import { Injectable } from '@angular/core';
import { UserService as CompleteUserService } from '@capturum/complete';
import { UserProfile } from '@features/user/models/user-profile.model';
import { Observable } from 'rxjs';
import { ApiHttpService, ApiSingleResult, ListOptions } from '@capturum/api';
import { AuthService } from '@capturum/auth';
import { unwrapResult } from '@core/operators/unwrap-result.operator';

@Injectable({
  providedIn: 'root',
})
export class UserProfileService<
  T extends UserProfile
> extends CompleteUserService {
  public endpoint = `user/profile`;

  constructor(
    private apiHttpService: ApiHttpService,
    private authService: AuthService
  ) {
    super(apiHttpService, authService);
  }

  public getUserProfile(options?: ListOptions): Observable<T> {
    return this.apiHttpService
      .get<ApiSingleResult<T>>(
        `/${this.endpoint}` + this.getOptionsQuery(options)
      )
      .pipe(unwrapResult());
  }

  public update<PROFILE_TYPE = T>(
    data: any,
    options?: ListOptions
  ): Observable<PROFILE_TYPE> {
    const url = `/${this.endpoint}` + this.getOptionsQuery(options);
    const payload = this.recursiveParse(data);

    return this.apiHttp
      .put<ApiSingleResult<PROFILE_TYPE>>(url, payload)
      .pipe(unwrapResult());
  }
}
