import { Injectable } from '@angular/core';
import { ApiIndexResult, ApiService, ListOptions } from '@capturum/api';
import { Installer } from '@features/installers/models/installer.model';
import { Observable, map } from 'rxjs';
import { Timeline } from '@features/leads/models/timeline.model';

@Injectable({
  providedIn: 'root',
})
export class InstallerService extends ApiService<Installer> {
  protected endpoint = 'installer';

  public getInstallerListWithFiveNearest(
    leadId: string,
    options: ListOptions
  ): Observable<ApiIndexResult<Installer>> {
    let url = `/lead/${leadId}/five-nearest-installers`;

    if (options) {
      url += this.getOptionsQuery(options);
    }

    return this.apiHttp.get<Installer[]>(url).pipe(
      map((result) => {
        return {
          data: result,
          meta: {},
        } as ApiIndexResult<Installer>;
      })
    );
  }

  public getTimeline(installerId: string): Observable<Timeline[]> {
    const url = `/${this.endpoint}/${installerId}/timeline`;

    return this.apiHttp.get<Timeline[]>(url);
  }

  public uploadInstallers(data: any): Observable<any> {
    let headers = this.apiHttp.getHeaders();

    if (data['file']) {
      headers = headers
        .delete('content-type')
        .append('enctype', 'multipart/form-data');

      data = this.toFormData({
        excel: data.file,
      });
    }

    return this.apiHttp.post(`/installer/excel`, data, { headers });
  }
}
