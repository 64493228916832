import { Component } from '@angular/core';
import { FieldType } from '@ngx-formly/core';
import { UntypedFormControl } from '@angular/forms';

@Component({
  selector: 'app-toggle-formly',
  templateUrl: './toggle.component.html',
  styleUrls: ['./toggle.component.scss'],
})
export class ToggleTypeComponent extends FieldType {
  public FormControl: typeof UntypedFormControl = UntypedFormControl;
}
