import { DefaultUrlSerializer, UrlSerializer, UrlTree } from '@angular/router';

/**
 * Make sure Angular's url serializer is able to deal with parenthesis in an url without causing route segment
 * failures. I noticed this happens when a query parameter contains a string like: 'admin (LG)'.
 *
 * @See: https://github.com/angular/angular/issues/10280#issuecomment-309129784
 */
export default class CustomUrlSerializer implements UrlSerializer {
  private _defaultUrlSerializer: DefaultUrlSerializer =
    new DefaultUrlSerializer();

  public parse(url: string): UrlTree {
    url = url.replace(/\(/g, '%28').replace(/\)/g, '%29');

    return this._defaultUrlSerializer.parse(url);
  }

  public serialize(tree: UrlTree): string {
    return this._defaultUrlSerializer
      .serialize(tree)
      .replace(/%28/g, '(')
      .replace(/%29/g, ')');
  }
}
