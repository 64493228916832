import { Component, OnInit } from '@angular/core';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { ListIndexMethod } from '@features/dashboard/models/list-index-method.enum';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-most-sold-models',
  templateUrl: './most-sold-models.component.html',
  styleUrls: ['./most-sold-models.component.scss'],
})
export class MostSoldModelsComponent implements OnInit {
  public tableColumns: InfoTableColumn[];

  public listIndexMethod = ListIndexMethod.MostSoldModels;

  constructor(private readonly translateService: TranslateService) {}

  public ngOnInit(): void {
    this.tableColumns = [
      {
        title: this.translateService.instant(
          'LeadPortal.dashboard.widget.model.title'
        ),
        field: 'model',
        type: InfoTableColumnType.Template,
        disableSort: true,
      },
      {
        title: this.translateService.instant(
          'LeadPortal.dashboard.widget.sold.title'
        ),
        field: 'sold',
        disableSort: true,
      },
    ];
  }
}
