import { NgModule } from '@angular/core';
import { CheckboxTypeComponent } from './types/checkbox/checkbox.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormlyModule } from '@ngx-formly/core';
import { CheckboxModule } from 'primeng/checkbox';
import { CastToPipe } from '@core/pipes/cast-to.pipe';
import { CapturumCheckboxModule } from '@capturum/ui/checkbox';
import { CommonModule } from '@angular/common';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import {
  CalendarTypeComponent,
  DropdownTypeComponent,
  InputTypeComponent,
} from '@capturum/formly';
import { EditableFormFieldWrapperComponent } from '@shared/modules/formly-types/wrappers/editable-form-field/editable-form-field-wrapper.component';
import { CapturumSharedModule } from '@capturum/ui/api';
import { CapturumTableModule } from '@capturum/ui/table';
import { ProductTableComponent } from '@shared/modules/formly-types/types/product-table/product-table.component';
import { ToggleTypeComponent } from '@shared/modules/formly-types/types/toggle/toggle.component';
import { CapturumInputSwitchModule } from '@capturum/ui/input-switch';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InstallerCategoryDropdownComponent } from '@shared/modules/formly-types/types/installer-category-dropdown/installer-category-dropdown.component';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { SharedModule } from '@shared/shared.module';
import { CapturumFormRendererModule } from '@capturum/builders/form-renderer';
import { ButtonTypeComponent } from '@shared/modules/formly-types/types/button/button.component';
import { CapturumButtonModule } from '@capturum/ui/button';
import {UploadFileComponent} from "@shared/modules/formly-types/types/upload-file/upload-file.component";
import {CapturumFileUploadModule} from "@capturum/ui/file-upload";

export const FORMLY_CUSTOM_TYPES = [
  {
    name: 'editableFormInput',
    component: InputTypeComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
  {
    name: 'editableFormDropdown',
    component: DropdownTypeComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
  {
    name: 'editableFormCheckbox',
    component: CheckboxTypeComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
  {
    name: 'editableFormTable',
    component: ProductTableComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
  {
    name: 'editableFormCalendar',
    component: CalendarTypeComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
  {
    name: 'editableFormToggle',
    component: ToggleTypeComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
  {
    name: 'editableFormButton',
    component: ButtonTypeComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
  {
    name: 'editableFormUploadFile',
    component: UploadFileComponent,
    wrappers: ['editableFormFieldWrapper'],
  },
];

export const FORMLY_WRAPPERS = [
  {
    name: 'editableFormFieldWrapper',
    component: EditableFormFieldWrapperComponent,
  },
];

export const FORMLY_TYPES_MODULE = FormlyModule.forRoot({
  types: [...FORMLY_CUSTOM_TYPES],
  wrappers: [...FORMLY_WRAPPERS],
});

@NgModule({
  declarations: [
    CastToPipe,
    CheckboxTypeComponent,
    ProductTableComponent,
    ToggleTypeComponent,
    ButtonTypeComponent,
    InstallerCategoryDropdownComponent,
    UploadFileComponent,
  ],
  imports: [
    ReactiveFormsModule,
    FormlyModule,
    FormsModule,
    CheckboxModule,
    CapturumCheckboxModule,
    CommonModule,
    CapturumInfoTableModule,
    CapturumSharedModule,
    CapturumTableModule,
    CapturumInputSwitchModule,
    InputSwitchModule,
    CapturumDropdownModule,
    SharedModule,
    CapturumFormRendererModule,
    CapturumButtonModule,
    CapturumFileUploadModule,
  ],
  exports: [
    CheckboxTypeComponent,
    ProductTableComponent,
    ToggleTypeComponent,
    ButtonTypeComponent,
  ],
})
export class FormlyTypesModule {}
