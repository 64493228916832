<div class="d-flex align-items-center" id="toggleContainer">
  <p-inputSwitch [formControl]="formControl | castTo: FormControl"
                 [disabled]="to?.disabled"
                 [readonly]="to?.disabled">
  </p-inputSwitch>

  <p *ngIf="to?.disabled && to?.textLabel" class="ml-2">
    {{ to?.textLabel}}
  </p>
</div>
