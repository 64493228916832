import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  startWith,
  takeUntil,
} from 'rxjs/operators';
import { BaseFilterService } from '@shared/modules/filters/services/base-filter.service';
import { BaseListClass } from '@core/classes/base-list.class';
import { FilterMetadata } from 'primeng/api';

@Component({
  template: '',
})
// tslint:disable-next-line:component-class-suffix
export class BaseFilterClass
  extends BaseListClass<any>
  implements OnInit, OnDestroy
{
  protected filterService: BaseFilterService;
  protected destroy$: Subject<boolean> = new Subject<boolean>();

  private _searchString: string;
  private _activeFilters: Record<string, FilterMetadata>;

  constructor(public injector: Injector) {
    super(injector);
    this.filterService = injector.get(BaseFilterService);
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public ngOnInit(): void {
    this.handleSettingUpConfiguration();
    this.handleFilterListeners();
  }

  public handleSettingUpConfiguration(): void {
    this.filterService.setFilterConfig(
      this.filterService.getFilterConfiguration()
    );
  }

  protected handleFilterListeners(): void {
    combineLatest([
      this.filterService.onSearch().pipe(
        startWith(null),
        distinctUntilChanged((prev, curr) => prev === curr)
      ),
      this.filterService.onFilterChange(),
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([activeSearch, activeFilters]) => {
        this.searchString = activeSearch;
        this.activeFilters = activeFilters;

        this.handleFilterChange(activeSearch, activeFilters);
      });
  }

  public get activeFilters(): Record<string, FilterMetadata> {
    return this._activeFilters;
  }

  public set activeFilters(activeFilters: Record<string, FilterMetadata>) {
    this._activeFilters = activeFilters;
  }

  public get searchString(): string {
    return this._searchString;
  }

  public set searchString(activeFilters: string) {
    this._searchString = activeFilters;
  }

  protected handleFilterChange(
    activeSearch?: string,
    activeFilters?: Record<string, FilterMetadata>
  ): void {}
}
