import { Injectable } from '@angular/core';
import { ApiHttpService } from '@capturum/api';
import { first, interval, Observable, Subject, takeUntil, timer } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';
import { UPLOAD_FETCH_INTERVAL } from '@core/constants/upload-fetch-interval.constant';

export interface batchStatusInfo {
  created_at: string;
  finished_at: string;
  has_failures: boolean;
  url?: string;
  file_name?: string;
}

@Injectable()
export class PollingHandlerService {
  public stopPolling$: Observable<boolean>;
  public isBatchDone: Subject<batchStatusInfo> = new Subject<batchStatusInfo>();

  public pollingInProgress = false;
  public pollingStarted = false;

  protected endpoint = `/batch-status`;

  private stopPolling: Subject<boolean> = new Subject<boolean>();

  constructor(private readonly apiHttp: ApiHttpService) {
    this.stopPolling$ = this.stopPolling.asObservable();
  }

  public startPeriodicalPolling(batchId: string): void {
    this.pollingStarted = true;

    const timeout = timer(300000);

    timeout.pipe(first()).subscribe(() => {
      if (this.pollingInProgress) {
        const batchInfo = {
          created_at: '',
          finished_at: '',
          has_failures: true,
        }

        this.isBatchDone.next(batchInfo);

        this.finishPeriodicalPolling();
      }
    });

    this.startPolling(batchId)
      .pipe(
        tap(() => {
          this.pollingInProgress = true;
        }),
        takeUntil(this.stopPolling$),
      )
      .subscribe((response: batchStatusInfo) => {
        if (response.finished_at !== null) {
          this.isBatchDone.next(response);

          this.finishPeriodicalPolling();
        }
      });
  }

  public startPolling(batchId: string): Observable<batchStatusInfo> {
    return interval(UPLOAD_FETCH_INTERVAL).pipe(
      switchMap((_) => {
        return this.checkForBatchIsDone(batchId);
      }),
    );
  }

  public finishPeriodicalPolling(): void {
    this.stopPolling.next(true);

    this.pollingInProgress = false;
    this.pollingStarted = false;
  }

  private checkForBatchIsDone(batchId: string): Observable<batchStatusInfo> {
    return this.apiHttp.get(`${this.endpoint}/${batchId}`, {});
  }
}
