export enum LeadStatus {
  Pending = 'lead_status_pending',
  New = 'lead_status_new',
  Check = 'lead_status_check',
  Proposed = 'lead_status_proposed',
  Allocated = 'lead_status_allocated',
  Appointment = 'lead_status_appointment_made',
  Planned = 'lead_status_planned',
  Quoted = 'lead_status_quoted',
  Approved = 'lead_status_approved',
  Installed = 'lead_status_installed',
  Reviewed = 'lead_status_reviewed',
  Dropped = 'lead_status_dropped',
  Archived = 'lead_status_archived',
}
