<div class="page-footer">
  <div class="brand-name">LG Lead Platform</div>

  <a href="/assets/{{ currentLang }}-terms-of-use.pdf" target="_blank">{{
    'terms-of-use' | translate
  }}</a>
  <!-- @TODO: add the link to manual when it will be provided -->
  <a
    *ngIf="currentLang === 'nl' || currentLang === 'en' || currentLang === 'fr'"
    href="/assets/{{ currentLang }}-{{
      currentEnv === 'Airconditioning' ? 'ac' : 'hp'
    }}-manual.pdf"
    target="_blank"
    >{{ 'manual' | translate }}</a
  >
  <a href="/assets/{{ currentLang }}-privacy-policy.pdf" target="_blank">{{
    'privacy-policy' | translate
  }}</a>
  <a href="{{ currentLang | instructionalVideoLink }}" target="_blank">{{
    'instructional-video' | translate
  }}</a>
</div>
