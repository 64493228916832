import { Injectable } from '@angular/core';
import { UserService as CompleteUserService } from '@capturum/complete';
import { Observable } from 'rxjs';
import { AppRoutes } from '@core/enums/routes.enum';
import { responseData } from '@capturum/builders/core';
import { User } from '@features/user/models/user.model';

@Injectable({
  providedIn: 'root',
})
export class UserService extends CompleteUserService {
  public sendActivationMail(userId: string): Observable<void> {
    return this.apiHttp.post(
      `/${this.endpoint}/${userId}/resend-activation`,
      {}
    );
  }

  public sendResetPasswordMail(userEmail: string): Observable<void> {
    return this.apiHttp.post(`/auth/recovery`, {
      email: `${userEmail}`,
    });
  }

  public switchEnvironment(): Observable<User> {
    return this.apiHttp
      .post(`/${AppRoutes.switchTenant}`, {})
      .pipe(responseData);
  }

  public canSwitchEnvironment(): Observable<{ canSwitchTenant: boolean }> {
    return this.apiHttp.post(`/${AppRoutes.canSwitchTenant}`, {});
  }

  public activateUser(userId: string): Observable<void> {
    return this.apiHttp.post(`/user/${userId}/activate`, {});
  }
}
