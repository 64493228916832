import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '@capturum/auth';
import { SettingIndexedDbModel } from '@capturum/complete';
import { AppRoutes } from '@core/enums/routes.enum';
import { Observable } from 'rxjs';
import { filterRequiredPermission } from '@core/operators/filter-required-permission.operator';
import { NgxPermissionsService } from 'ngx-permissions';
import { MenuItem as CompleteMenuItem } from 'primeng/api';
import { finalize, first } from 'rxjs/operators';
import { UserService } from '@features/user/services/user.service';
import { CSSVarNames, ThemeService } from '@capturum/ui/api';
import { Tenant } from '@core/enums/tenant.enum';
import { User } from '../../../../../features/user/models/user.model';

export interface MenuItem extends CompleteMenuItem {
  requiredPermission?: string;
  hidden?: boolean;
}

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent implements OnInit {
  public menuItems$: Observable<MenuItem[]>;
  public allItems: MenuItem[];
  public user: User;
  public canSwitch = false;

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly ngxPermissionsService: NgxPermissionsService,
    private readonly themeService: ThemeService,
    private userService: UserService
  ) {
    this.user = this.authService.getUser() as User;
  }

  public ngOnInit(): void {
    this.setTheme(this.user?.tenant_name);

    this.userService.canSwitchEnvironment().subscribe((result) => {
      this.canSwitch = result.canSwitchTenant;

      this.allItems = this.getMenuItems();

      this.menuItems$ = this.getFilteredMenuItems(this.allItems);
    });
  }

  public environmentTitle = `${SettingIndexedDbModel.getValue(
    'LeadPortal.tenant_translation_key'
  )}`;

  public getFilteredMenuItems(navItems: MenuItem[]): Observable<MenuItem[]> {
    return this.ngxPermissionsService.permissions$.pipe(
      first(),
      filterRequiredPermission(navItems)
    );
  }

  public executeCommand(item: MenuItem): void {
    if (item.command) {
      item.command();
    } else if (item.routerLink) {
      this.router.navigateByUrl(item.routerLink);
    }
  }

  private getMenuItems(): MenuItem[] {
    return [
      {
        label: 'link.profile',
        icon: 'fas fa-user-circle',
        routerLink: `/${AppRoutes.user}/${AppRoutes.profile}`,
      },
      {
        label: 'config.manage.title',
        icon: 'fas fa-cog',
        routerLink: `/${AppRoutes.config}`,
        requiredPermission: 'module.manage.tenant', // @TODO: Find a permission only admins have
      },
      {
        label: `${SettingIndexedDbModel.getValue(
          'LeadPortal.tenant_switch_translation_key'
        )}`,
        icon: 'fas fa-duotone fa-repeat',
        hidden: !this.canSwitch,
        command: () => {
          this.userService.switchEnvironment().subscribe((data) => {
            const user = {
              ...this.user,
              country_base_data_value_id: data.country_base_data_value_id,
              current_role_id: data.current_role_id,
              tenant_name: data?.tenant_name,
            };

            Object.keys(localStorage).forEach((key) => {
              if (key.includes('filters')) {
                localStorage.removeItem(key);
              }
            });

            localStorage.setItem('user', JSON.stringify(user));
            location.reload();
          });
        },
      },
      {
        label: 'link.logout',
        icon: 'fas fa-sign-in-alt',
        command: () => {
          this.authService
            .logout()
            .pipe(
              first(),
              finalize(() => {
                this.router.navigate(['/', AppRoutes.auth, AppRoutes.login]);
              })
            )
            .subscribe();
        },
      },
    ];
  }

  private setTheme(tenantName?: string): void {
    if (tenantName === Tenant.AIRCONDITIONING) {
      this.themeService.setProps({
        [CSSVarNames.Primary]: '#0096AA',
        [CSSVarNames.Secondary]: '#006A73',
      });
    }
  }
}
