enum EntityRoutes {
  admin = 'admin',
  role = 'role',
  roles = 'roles',
  user = 'users',
  translation = 'translation',
  baseData = 'base-data',
  setting = 'setting',
  products = 'products',
}

enum PageRoutes {
  public = 'public',
  dashboard = 'dashboard',
  installer = 'installer',
  lead = 'lead',
  distributor = 'distributor',
  review = 'review-lead',
  proposedLead = 'proposed-lead',
  userAgreement = 'user-agreement',
  questionnaire = 'questionnaire',
}

enum HelperRoutes {
  profile = 'profile',
  config = 'config',
  switchTenant = 'tenant/switch',
  canSwitchTenant = 'tenant/can-switch',
}

export enum BuilderRoutes {
  path = 'builders',
  configuration = 'builders/configuration',
  release = 'builders/release',
}

export enum GeneralRoutes {
  detail = 'detail',
  list = 'list',
  test = 'test',
  view = 'view',
  add = 'add',
  edit = 'edit',
}

enum AuthRoutes {
  auth = 'auth',
  login = 'login',
}

export type AppRoutes = typeof AppRoutes;
export const AppRoutes = {
  ...EntityRoutes,
  ...GeneralRoutes,
  ...PageRoutes,
  ...HelperRoutes,
  ...AuthRoutes,
  builders: {
    ...BuilderRoutes,
  },
};
