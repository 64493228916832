export const API_DATE_PROPERTIES = [
  'created_at',
  'updated_at',
  'deleted_at',
  'installation_date',
  'preferred_installation_date',
  'quote_application_date',
  'appointment_date',
];

export const API_DATE_BLACKLIST = [
  '/api/role/translation',
  '/api/role/module',
  '/api/base-data-key',
  '/api/role/setting',
  '/api/role/base-data',
  '/api/role/permission',
];

export enum DateFormats {
  displayTimeFormat = 'HH:mm',
  displayFormat = 'dd-MM-yyyy',
  displayShortFormat = 'dd-MM-yy',
  displayWithTimeFormat = 'dd-MM-YYYY HH:mm',
  sendFormat = 'YYYY-MM-DD',
  sendFormatWithTime = 'YYYY-MM-DDTHH:mm:ss',
  sendFormatWithTimeAndDashes = 'YYYY-MM-DDTHH-mm-ss',
}
