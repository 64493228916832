import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from '@core/services/auth.service';
import { BaseDataService } from '@core/services/base-data.service';
import { takeUntil } from 'rxjs';
import { DestroyBase } from '@capturum/shared';
import { SettingIndexedDbModel } from '@capturum/complete';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterComponent extends DestroyBase implements OnInit {
  public currentLang: string;
  public currentEnv: string | boolean;

  constructor(
    private readonly translateService: TranslateService,
    protected readonly authService: AuthService,
    protected readonly baseDataService: BaseDataService,
    protected readonly cdr: ChangeDetectorRef
  ) {
    super();
    this.currentLang = this.translateService.currentLang;
  }

  public ngOnInit(): void {
    this.translateService.onLangChange
      .pipe(takeUntil(this.destroy$))
      .subscribe((langEvent) => {
        this.currentLang = langEvent.lang;
        this.cdr.detectChanges();
      });
    this.currentEnv = SettingIndexedDbModel.getValue('general.tenant_name');
  }
}
