<div class="rating">
  <div *ngIf="rating || formControlName; else noRating" class="d-flex">
    <i *ngFor="let star of stars; let index = index; let last = last"
       [rating]="rating"
       [readonly]="disabled"
       [currentStarIndex]="index + 1"
       [attr.data-test]="'star-rating-' + index"
       appStarRating
       (click)="onStarClick(index)">
    </i>

    <div *ngIf="disabled && showRatingDigits" class="text-rating">
      ({{ (+rating)?.toFixed(2) }})
    </div>
  </div>
</div>

<ng-template #noRating>
  <span class="no-rating">{{ 'LeadPortal.installer.no-rating.label' | translate }}</span>
</ng-template>
