<div class="header pt-3" *ngIf="title">
  <div class="title" *ngIf="title">
    {{ title }}
  </div>

  <hr *ngIf="title && showDivider" />
</div>

<div [ngClass]="[wrapperClass]" *ngIf="(formState$ | async) as formState">
  <ng-container *ngIf="formState === FormState.LOADING; else formTemplate">
    <!-- @TODO: Add skeleton loader when form builder supports loaders as well -->
  </ng-container>

  <ng-template #formTemplate>
    <formly-form [model]="model"
                 [class]="formlyClass"
                 [fields]="fields"
                 [options]="options$ | async"
                 [form]="form">
    </formly-form>
  </ng-template>
</div>
