import { Component, Inject, Optional } from '@angular/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { UPLOAD_POLLING } from '@core/provider/polling.provider';
import { PollingHandlerService } from '../../../leads/services/polling-handler.service';
import { InstallerService } from '../../services/installer.service';

@Component({
  selector: 'app-upload-installers',
  templateUrl: './upload-installers.component.html',
  styleUrls: ['./upload-installers.component.scss'],
})
export class UploadInstallersComponent {
  constructor(
    private readonly installerService: InstallerService,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    @Optional()
    @Inject(UPLOAD_POLLING)
    private uploadPollingService: PollingHandlerService
  ) {}

  public loadFile(data: Record<string, any>): void {
    this.installerService.uploadInstallers(data).subscribe((response) => {
      this.uploadPollingService.startPeriodicalPolling(
        response?.original?.batch_id
      );

      this.displayToast();

      this.dynamicDialogRef.close();
    });
  }

  private displayToast(): void {
    this.toastService.info(
      this.translateService.instant('LeadPortal.lead.upload-button.title'),
      this.translateService.instant(
        'LeadPortal.lead.upload-button.started.message'
      )
    );
  }
}
