import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { FormlyFormOptions } from '@ngx-formly/core';
import { FormState } from '@core/enums/form-state.enum';
import { FormType } from '@core/enums/form-type.enum';
import { delay } from 'rxjs/operators';

@Injectable()
export class FormStateService {
  private _formlyOptions: FormlyFormOptions = {
    formState: {
      disabled: true,
    },
  };

  private _options: BehaviorSubject<FormlyFormOptions> = new BehaviorSubject(
    this._formlyOptions
  );
  private _formState: BehaviorSubject<FormState> =
    new BehaviorSubject<FormState>(FormState.LOADING);
  private _formType: BehaviorSubject<FormType> = new BehaviorSubject<FormType>(
    FormType.ADD
  );
  private _onReset: Subject<void> = new Subject<void>();

  public updateInitialValue(): void {
    if (this._formlyOptions.updateInitialValue) {
      this._formlyOptions?.updateInitialValue();
    }
  }

  public resetModel(): void {
    if (this._formlyOptions.resetModel) {
      this._formlyOptions.resetModel();
    }

    this._onReset.next();
  }

  public get options(): Observable<FormlyFormOptions> {
    return this._options.asObservable();
  }

  public enableForm(): void {
    this._formlyOptions.formState.disabled = false;

    this._options.next(this._formlyOptions);
  }

  public disableForm(): void {
    this._formlyOptions.formState.disabled = true;

    this._options.next(this._formlyOptions);
  }

  public get onReset(): Observable<void> {
    return this._onReset.asObservable();
  }

  public get onFormStateChange(): Observable<FormState> {
    return this._formState.asObservable().pipe(delay(0));
  }

  public get onFormTypeChange(): Observable<FormType> {
    return this._formType.asObservable().pipe(delay(0));
  }

  public triggerFormTypeChange(type: FormType): void {
    this._formType.next(type);
  }

  public triggerFormStateChange(state: FormState): void {
    this._formState.next(state);

    if (state === FormState.INTERACTING || state === FormState.SUBMITTING) {
      this.enableForm();
    } else {
      this.disableForm();
    }
  }
}
