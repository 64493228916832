<app-header [showNavbar]="showNavbar"></app-header>

<div id="wrapper">
  <div class="container page-wrapper gray-bg" [class.showNavbar]="showNavbar">
    <div class="row">
      <div class="col-12">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<app-footer></app-footer>
