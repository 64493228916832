import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { FormState } from '@core/enums/form-state.enum';
import { FormStateService } from '@core/services/form-state.service';
import { FormType } from '@core/enums/form-type.enum';

@Component({
  selector: 'app-detail-layout',
  templateUrl: './detail-layout.component.html',
  styleUrls: ['./detail-layout.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DetailLayoutComponent implements OnInit, OnDestroy {
  @Input() public title: string | Observable<string>;
  @Input() public showDivider = true;
  @Input() public backRouteLink: string;
  @Input() public entity: string;
  @Input() public hideEditButton = false;

  public FormState: typeof FormState = FormState;
  public formState: FormState;

  public FormType: typeof FormType = FormType;
  public formType: FormType;

  private _subscription: Subscription = new Subscription();

  constructor(
    private readonly formStateService: FormStateService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {}

  public ngOnInit(): void {
    this._subscription.add(
      this.formStateService.onFormStateChange.subscribe((formState) => {
        this.formState = formState;
        this.changeDetectorRef.detectChanges();
      })
    );

    this._subscription.add(
      this.formStateService.onFormTypeChange.subscribe((formType) => {
        this.formType = formType;
      })
    );
  }

  public ngOnDestroy(): void {
    this._subscription.unsubscribe();
  }

  public onEditClick(): void {
    this.formStateService.updateInitialValue();
    this.formStateService.triggerFormStateChange(FormState.INTERACTING);
  }

  public onCancelClick(): void {
    this.formStateService.resetModel();
    this.formStateService.triggerFormStateChange(
      this.formType === FormType.EDIT
        ? FormState.DISPLAYING
        : FormState.CANCELLING
    );
  }

  public onSubmitClick(): void {
    this.formStateService.triggerFormStateChange(FormState.SUBMITTING);
  }
}
