import { Observable } from 'rxjs';
import { FilterMetadata } from 'primeng/api';
import { FilterRequest } from '@shared/modules/filters/models/filter-request.model';
import { FilterConfig } from '@shared/modules/filters/models/filter-config.model';

export abstract class FilterService {
  public abstract onSearch(): Observable<string>;

  public abstract onFilterChange(): Observable<FilterMetadata>;

  public abstract onFilterConfig(): Observable<FilterConfig[]>;

  public abstract setSearch(search: string): void;

  public abstract addFilter(filter: FilterRequest): void;

  public abstract removeFilter(field: string): void;

  public abstract resetFilters(): void;

  public abstract getFilterConfiguration(): FilterConfig[];

  public abstract setFilterConfig(filterConfig: FilterConfig[]): void;
}
