import { BUILD_TIMESTAMP } from './timestamp';

const packageJson = require('../../package.json');

export const environment = {
  appName: 'Lead Platform - Test',
  production: false,
  hmr: false,
  name: 'Test',
  baseUrl: 'https://lg-electronics-test.staging.emendis.nl/api',
  sentryUrl: 'https://b71a143b898f47bfbc9347b944bbbda2@o4504360297496576.ingest.sentry.io/4504377890308096',
  databaseName: 'lg.test',
  version: packageJson.version + '.' + BUILD_TIMESTAMP,
  redirectAfterLogin: '/',
  url: 'https://lg-electronics.test.emendis.nl',
};
