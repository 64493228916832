import { Injectable } from '@angular/core';
import { ApiService } from '@capturum/api';
import { Lead } from './../models/lead.model';
import { DropReason } from './../models/drop-reason.model';
import { Observable } from 'rxjs';
import { IncludeKey } from '@core/enums/include-key.enum';
import { unwrapResult } from '@core/operators/unwrap-result.operator';
import { MapItem } from '@capturum/auth';
import { map } from 'rxjs/operators';
import { responseData } from '@capturum/builders/core';
import { Timeline } from '../models/timeline.model';
import { BaseDataValueApi } from '@capturum/complete/lib/domain/base-data/models/base-data-value.api-model';

@Injectable({
  providedIn: 'root',
})
export class LeadApiService extends ApiService<Lead> {
  protected endpoint = 'lead';

  private _defaultIncludes = [
    IncludeKey.LatestStatuses,
    IncludeKey.status,
    IncludeKey.statuses,
  ];

  get defaultIncludes(): IncludeKey[] {
    return this._defaultIncludes;
  }

  public resendProposal(leadId: string): Observable<Lead> {
    const url = `/${this.endpoint}/${leadId}/resend-proposal`;

    return this.apiHttp.put(url, {});
  }

  public resendReviewRequest(leadId: string): Observable<Lead> {
    const url = `/${this.endpoint}/${leadId}/resend-review-request`;

    return this.apiHttp.put(url, {});
  }

  public dropLeadById(
    leadId: string,
    dropReason: DropReason
  ): Observable<Lead> {
    let url = `/${this.endpoint}/${leadId}/drop`;

    url += this.getOptionsQuery({ include: this._defaultIncludes });

    return this.apiHttp.post(url, dropReason).pipe(unwrapResult());
  }

  public finishInstallation(leadId: string): Observable<Lead> {
    let url = `/${this.endpoint}/${leadId}/installed`;

    url += this.getOptionsQuery({ include: this._defaultIncludes });

    return this.apiHttp.post(url, {}).pipe(unwrapResult());
  }

  public restoreLead(leadId: string): Observable<Lead> {
    const url = `/${
      this.endpoint
    }/${leadId}/restore-dropped${this.getOptionsQuery({
      include: this._defaultIncludes,
    })}`;

    return this.apiHttp.post(url, {}).pipe(unwrapResult());
  }

  public acceptLead(leadId: string): Observable<Lead> {
    let url = `/${this.endpoint}/${leadId}/accept`;

    url += this.getOptionsQuery({ include: this._defaultIncludes });

    return this.apiHttp.post(url, {}).pipe(unwrapResult());
  }

  public sendToOtherInstaller(leadId: string): Observable<Lead> {
    let url = `/${this.endpoint}/${leadId}/repropose`;

    url += this.getOptionsQuery({ include: this._defaultIncludes });

    return this.apiHttp.put(url, {}).pipe(unwrapResult());
  }

  public getTimeline(leadId: string): Observable<Timeline[]> {
    const url = `/${this.endpoint}/${leadId}/timeline`;

    return this.apiHttp.get<Timeline[]>(url);
  }

  public getDuplicates(leadId: string): Observable<Lead[]> {
    const url = `/${this.endpoint}/${leadId}/duplicates`;

    return this.apiHttp.get<Lead[]>(url);
  }

  public getProducts(productType: string): Observable<MapItem[]> {
    const url = `/product/${productType}`;

    return this.apiHttp.get<any>(url).pipe(
      responseData,
      map((products) => {
        return products.map((product) => {
          return {
            value: product.id,
            label: product.name,
          };
        });
      })
    );
  }

  public uploadLeads(data: any): Observable<any> {
    let headers = this.apiHttp.getHeaders();

    if (data['file']) {
      headers = headers
        .delete('content-type')
        .append('enctype', 'multipart/form-data');

      data = this.toFormData({
        excel: data.file,
      });
    }

    return this.apiHttp.post(`/lead/upload`, data, { headers });
  }

  public getDropReasons(): Observable<BaseDataValueApi[]> {
    const url = `/lead/drop-reasons`;

    return this.apiHttp.get<BaseDataValueApi[]>(url);
  }

  public approveLead(leadId: string): Observable<Lead> {
    let url = `/${this.endpoint}/${leadId}/approve`;

    url += this.getOptionsQuery({ include: this._defaultIncludes });

    return this.apiHttp.post(url, {}).pipe(unwrapResult());
  }

  public disapproveLead(leadId: string): Observable<Lead> {
    let url = `/${this.endpoint}/${leadId}/disapprove`;

    url += this.getOptionsQuery({ include: this._defaultIncludes });

    return this.apiHttp.post(url, {}).pipe(unwrapResult());
  }

  public uploadQuotePricePDF(
    data: Record<string, any>,
    leadId: string
  ): Observable<void> {
    let headers = this.apiHttp.getHeaders();

    if (data['file']) {
      headers = headers
        .delete('content-type')
        .append('enctype', 'multipart/form-data');

      data = this.toFormData({
        file: data.file,
      });
    }

    return this.apiHttp.post(`/lead/${leadId}/upload-quote-price-pdf`, data, {
      headers,
    });
  }

  public downloadFile(leadId: string): Observable<Blob> {
    return this.apiHttp.get(
      `/lead/${leadId}/download-quote-price-pdf`,
      { responseType: 'blob' }
    );
  }

  public removeFile(leadId: string): Observable<void> {
    return this.apiHttp.delete(`/lead/${leadId}/delete-quote-price-pdf`);
  }
}
