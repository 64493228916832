<div *ngIf="config$ | async as config"
     class="login-screen {{ config?.displayInputsLabel ? 'display' : 'hide' }}-inputs-label"
     [style.background-image]="'url(' + config?.backgroundImageUrl + ')'">
  <div class="login-wrapper">
    <div class="content-wrapper row">
      <div class="content-left col-12 col-md-5">
        <div [style.background-image]="'url(' + config?.logoImageUrl + ')'"
             class="logo"
             data-test="logo">
        </div>
      </div>

      <div class="content-right col-12 col-md-7">
        <!-- View/routes wrapper-->
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>

<div class="notifications-container" *ngIf="notificationSubject$ | async as notification">
    <div class="notification warning" [@flyDown]>
        <div class="icon">
          <i class="fas fa-exclamation-triangle"></i>
        </div>

        <div class="description">
          <h5 class="header">
            {{ notification.summary  | translate}}
          </h5>

          <span class="message">
            {{ notification.detail | translate }}
          </span>
        </div>

        <div class="action">
          <span class="close-click" (click)="hideNotification()">×</span>
        </div>
    </div>
</div>

<app-footer></app-footer>
