import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { AppRoutes } from '@core/enums/routes.enum';
import { AuthService } from '@core/services/auth.service';

@Injectable({
  providedIn: 'root',
})
export class UserTypeGuard implements CanActivate {
  constructor(private authService: AuthService, private router: Router) {}

  public canActivate(): Observable<boolean> {
    const isTelemarketing = this.authService.isTelemarketing();

    if (isTelemarketing) {
      this.router.navigate([AppRoutes.lead]);

      return of(false);
    } else {
      return of(true);
    }
  }
}
