<div class="user-agreement">
  <div class="user-agreement__image">
    <img src="/assets/images/logo_with_text.png" width="150px" alt="Logo LG">
  </div>

  <div class="inner-user-agreement">
    <div class="inner-user-agreement__header">
      <h1>{{ 'LeadPortal.user-agreement.header' | translate }}</h1>
    </div>

    <p class="inner-user-agreement__paragraph">
      {{ 'LeadPortal.user-agreement.salutation' | translate }}
    </p>

    <p class="inner-user-agreement__paragraph">
      {{ 'LeadPortal.user-agreement.content' | translate }}
    </p>

    <p class="inner-user-agreement__paragraph" [innerHtml]="'LeadPortal.user-agreement.footer-text' | translate">
    </p>

    <div class="d-flex">
      <cap-checkbox [(ngModel)]="checked">
      </cap-checkbox>

      <label [innerHtml]="'LeadPortal.user-agreement.checkbox-label' | translate"></label>
    </div>

    <cap-button styleClass="primary" [label]="'LeadPortal.user-agreement.button.label' | translate" (onClick)="submit()"></cap-button>
  </div>
</div>
