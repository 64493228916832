import * as Sentry from '@sentry/angular-ivy';
import { ErrorHandler, Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { environment } from '@environments/environment';
@Injectable()
export class SentryErrorHandler implements ErrorHandler {
  public handleError(error: any): void {
    let errorReason = 'Unknown reason';

    if (error instanceof HttpErrorResponse) {
      // Handle the HTTP errors without logging the error to Sentry
      return;
    } else if (error) {
      // Handle all errors and set as much information as possible in tags
      if (error.name === 'EmptyError') {
        errorReason = 'No data or empty array returned';
      } else if (error.name === '<unknown>' || error.title === 'unknown') {
        errorReason = `The <unknown> title usually means that there was no error message when the error was
                       generated or is not caught and handled by the error handling system`;
      }

      Sentry.captureException(error, {
        tags: {
          headers: error.headers,
          message: error.message,
          name: error.name,
          reason: errorReason,
        },
      });
    }
  }
}
export function getErrorHandler(): ErrorHandler {
  if (environment.production) {
    return new SentryErrorHandler();
  }

  return new ErrorHandler();
}
