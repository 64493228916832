<router-outlet></router-outlet>

<cap-toast position="top-right"></cap-toast>

<p-confirmDialog #cd appendTo="body">
  <p-footer>
    <cap-button styleClass="secondary"
                type="button"
                [label]="(confirmation?.rejectLabel | translate) || ('confirm.no' | translate)"
                (click)="cd.reject()">
    </cap-button>

    <cap-button data-test="dialog-confirm-button"
                styleClass="primary"
                type="button"
                [label]="(confirmation?.acceptLabel | translate) || ('confirm.yes' | translate)"
                (click)="cd.accept()">
    </cap-button>
  </p-footer>
</p-confirmDialog>
