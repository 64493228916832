import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { AuthService } from '@core/services/auth.service';

@Directive({
  selector: '[appOnlyRoles]',
})
export class OnlyRolesDirective {
  private hasView: boolean;

  constructor(
    private authService: AuthService,
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) {}

  @Input('appOnlyRoles')
  public set roles(value: string[]) {
    this.changeVisibility(value);
  }

  private changeVisibility(roles: string[]): void {
    if (this.authService.isOneOfRoles(roles) && !this.hasView) {
      this.viewContainer.createEmbeddedView(this.templateRef);
      this.hasView = true;
    } else if (!this.authService.isOneOfRoles(roles) && this.hasView) {
      this.viewContainer.clear();
      this.hasView = false;
    }
  }
}
