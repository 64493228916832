<app-table-widget-layout [dependingFilters]="['dates', 'distributor_id', 'regions', 'utm']"
                         [tableName]="'LeadPortal.dashboard.widget.best-performing-installers.title' | translate"
                         [listIndexMethod]="listIndexMethod"
                         [entityName]="Entity.INSTALLER"
                         [columnsData]="tableColumns">
  <ng-container headerActions>
    <a class="link"
       [routerLink]="'/' + AppRoutes.installer">{{ 'LeadPortal.dashboard.widget.lead-status.action.view-all-installers' | translate }}</a>
  </ng-container>
</app-table-widget-layout>
