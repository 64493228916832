<ng-container>
  <div class="d-flex">
    <div class="w-100">
      <ng-content select="[filters]"></ng-content>
    </div>

    <div class="flex-shrink-1">
      <ng-content select="[buttons]"></ng-content>
    </div>
  </div>
</ng-container>
