import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-back-to-overview',
  templateUrl: './back-to-overview.component.html',
  styleUrls: ['./back-to-overview.component.scss'],
})
export class BackToOverviewComponent {
  @Input() public text: string;
  @Input() public url: string | string[];
}
