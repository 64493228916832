import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe, PercentPipe } from '@angular/common';
import { NgxPermissionsModule } from 'ngx-permissions';
import { CapturumButtonModule } from '@capturum/ui/button';
import { TranslateModule } from '@ngx-translate/core';
import { CapturumSharedModule } from '@capturum/ui/api';
import { InstallerCategoryWidgetComponent } from './dumb-components/installer-category-widget/installer-category-widget.component';
import { InstallerOverallRatingWidgetComponent } from './dumb-components/installer-overall-rating-widget/installer-overall-rating-widget.component';
import { StarRatingComponent } from './dumb-components/star-rating/star-rating.component';
import { StarRatingDirective } from './directives/star-rating.directive';
import { TableHeaderLayoutComponent } from '@shared/modules/layout/layouts/table-header-layout/table-header-layout.component';
import { CapturumInputModule } from '@capturum/ui/input';
import { CapturumMultiSelectModule } from '@capturum/ui/multi-select';
import { CapturumDropdownModule } from '@capturum/ui/dropdown';
import { CapturumCalendarModule } from '@capturum/ui/calendar';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PageHeaderComponent } from '@shared/modules/layout/components/page-header/page-header.component';
import { EditableFormLayoutComponent } from '@shared/modules/layout/layouts/editable-form-layout/editable-form-layout.component';
import { EditableFormFieldWrapperComponent } from '@shared/modules/formly-types/wrappers/editable-form-field/editable-form-field-wrapper.component';
import { DetailLayoutComponent } from '@shared/modules/layout/layouts/detail-layout/detail-layout.component';
import { BackToOverviewComponent } from '@shared/modules/layout/components/back-to-overview/back-to-overview.component';
import { UserMenuComponent } from '@shared/modules/layout/components/user-menu/user-menu.component';
import { NavigationBarComponent } from '@shared/modules/layout/components/navigation-bar/navigation-bar.component';
import { BasicLayoutComponent } from '@shared/modules/layout/layouts/basic-layout/basic-layout.component';
import { HeaderComponent } from '@shared/modules/layout/components/header/header.component';
import { RouterModule } from '@angular/router';
import { HorizontalNavbarModule } from '@capturum/ui/horizontal-navbar';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { FormlyModule } from '@ngx-formly/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { PageLayoutComponent } from '@shared/modules/layout/layouts/page-layout/page-layout.component';
import { LeadCommentsWidgetComponent } from './dumb-components/lead-comments-widget/lead-comments-widget.component';
import { InstallerQuotesSentComponent } from './dumb-components/installer-quotes-sent/installer-quotes-sent.component';
import { CardLayoutComponent } from './modules/layout/layouts/card-layout/card-layout.component';
import { PipeFactoryPipe } from './pipes/pipe-factory.pipe';
import { CapturumInfoTableModule } from '@capturum/ui/info-table';
import { DialogModule } from 'primeng/dialog';
import { MultiSelectModule } from 'primeng/multiselect';
import { InstallerCategoryIconComponent } from './dumb-components/installer-category-icon/installer-category-icon.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormTooltipComponent } from './dumb-components/form-tooltip/form-tooltip.component';
import { OnlyRolesDirective } from './directives/only-roles.directive';
import { BaseDataValueByIdPipe } from './pipes/base-data-value-by-id.pipe';
import { TableWidgetLayoutComponent } from './modules/layout/layouts/table-widget-layout/table-widget-layout.component';
import { FooterComponent } from '@shared/modules/layout/components/footer/footer.component';
import { LoginLayoutComponent } from '@shared/modules/layout/components/login-layout/login-layout.component';
import { InstructionalVideoLinkPipe } from './pipes/instructional-video-link.pipe';

const COMPONENTS = [
  InstallerCategoryWidgetComponent,
  InstallerOverallRatingWidgetComponent,
  StarRatingComponent,
  PageHeaderComponent,
  EditableFormFieldWrapperComponent,
  BackToOverviewComponent,
  UserMenuComponent,
  NavigationBarComponent,
  HeaderComponent,
  LeadCommentsWidgetComponent,
  InstallerQuotesSentComponent,
  FooterComponent,
  LoginLayoutComponent,
];

const LAYOUTS = [
  PageLayoutComponent,
  TableHeaderLayoutComponent,
  BasicLayoutComponent,
  EditableFormLayoutComponent,
  CardLayoutComponent,
  DetailLayoutComponent,
  TableWidgetLayoutComponent,
];

const DIRECTIVES = [StarRatingDirective, PipeFactoryPipe];

@NgModule({
  declarations: [
    ...COMPONENTS,
    ...DIRECTIVES,
    ...LAYOUTS,
    InstallerCategoryIconComponent,
    FormTooltipComponent,
    OnlyRolesDirective,
    BaseDataValueByIdPipe,
    InstructionalVideoLinkPipe,
  ],
  imports: [
    CommonModule,
    NgxPermissionsModule,
    CapturumButtonModule,
    TranslateModule,
    CapturumSharedModule,
    CapturumInputModule,
    CapturumMultiSelectModule,
    CapturumDropdownModule,
    CapturumCalendarModule,
    FormsModule,
    RouterModule,
    HorizontalNavbarModule,
    OverlayPanelModule,
    FormlyModule,
    ReactiveFormsModule,
    ProgressSpinnerModule,
    CapturumInfoTableModule,
    DialogModule,
    MultiSelectModule,
    TooltipModule,
  ],
  exports: [
    NgxPermissionsModule,
    TranslateModule,
    CapturumSharedModule,
    ...COMPONENTS,
    ...DIRECTIVES,
    ...LAYOUTS,
    FormTooltipComponent,
    InstallerCategoryIconComponent,
    BaseDataValueByIdPipe,
    OnlyRolesDirective,
  ],
  providers: [CurrencyPipe, PercentPipe],
})
export class SharedModule {}
