import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

export class FormUtils {
  public static markAsTouched(formGroup: UntypedFormGroup): void {
    Object.keys(formGroup.controls).forEach((field) => {
      const control = formGroup.get(field);

      if (control instanceof UntypedFormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof UntypedFormGroup) {
        this.markAsTouched(control);
      }
    });
  }
}
