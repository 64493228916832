<app-card-layout [title]="tableName" disableHeaderSpacing="true">
  <ng-container header>
    <ng-content select="[headerActions]"></ng-content>
  </ng-container>

  <cap-info-table #infoTable
                  class="list"
                  [columns]="columnsData"
                  [loading]="loading"
                  [lazyLoading]="true"
                  [pagination]="false"
                  [data]="tableData"
                  [pagination]="false"
                  (onRowClick)="onRowClick($event)"
                  (onLazyLoad)="loadTableData($event)">
    <ng-template capTemplate="rating" let-item="item">
      <app-star-rating [disabled]="true" [rating]="item?.rating"></app-star-rating>
    </ng-template>

    <ng-template capTemplate="model" let-item="item">
      {{ item?.model | translate }}
    </ng-template>

    <ng-template capTemplate="customer_type" let-item="item">
      {{ 'base-data.' + item?.type_base_data_value_id | translate }}
    </ng-template>
  </cap-info-table>
</app-card-layout>
