export enum UTMAnswer {
  DIRECT = 'Direct',
  FACEBOOK = 'fb',
  LG = 'lg',
  GOOGLE = 'google',
  FB_MESSENGER = 'msg',
  INSTAGRAM = 'ig',
  KIESKEURIG = 'kieskeurig',
  LGAIRCOKOPEN = 'lgaircokopen',
}
