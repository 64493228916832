import { Component, Injector, OnDestroy, OnInit } from '@angular/core';
import { FormState } from '@core/enums/form-state.enum';
import { FormType } from '@core/enums/form-type.enum';
import { FormUtils } from '@core/utils/form.utils';
import {
  CapturumFormRendererComponent,
  FormRendererService,
  FormSaverService,
} from '@capturum/builders/form-renderer';
import { BaseDetailClass } from '@core/classes/base-detail.class';
import { clone } from '@capturum/builders/core';
import { ApiService } from '@capturum/api';

@Component({
  template: '',
})
// tslint:disable-next-line:component-class-suffix
export class BaseEntityClass<T>
  extends BaseDetailClass<T>
  implements OnInit, OnDestroy
{
  public currentFormState: FormState;
  public FormState = FormState;
  public FormType = FormType;
  public context: { [key: string]: string | boolean };
  public formKey: string;
  public entityTransformToDateFields = [];
  public formRenderer: CapturumFormRendererComponent;

  protected readonly formRendererService: FormRendererService;
  protected readonly formSaverService: FormSaverService;

  public constructor(
    public injector: Injector,
    public apiService: ApiService<T>
  ) {
    super(injector, apiService);

    this.formRendererService = injector.get(FormRendererService);
    this.formSaverService = injector.get(FormSaverService);
  }

  public ngOnInit(): void {
    super.ngOnInit();

    this.formKey = `form_${this.entity}-detail`;

    this._subscription.add(
      this.formStateService.onFormStateChange.subscribe((formState) => {
        this.currentFormState = formState;
      })
    );
  }

  public submit(): void {
    this.handleSubmit(null);
  }

  protected handleSubmit(payload: any): void {
    const onSubmitSuccess = () => {
      this.toastService.success(this.modelTranslation, this.saveMessage);
      this.onCancel();
    };

    const onSubmitError = () => {
      FormUtils.markAsTouched(this.formGroup);

      this.formStateService.triggerFormStateChange(FormState.INTERACTING);
    };

    this.formSaverService
      .submit(this.formKey)
      .subscribe(onSubmitSuccess, onSubmitError);
  }

  protected transformDefaultModelValues(response: any): any {
    if (!response) {
      return response;
    }

    return Object.entries(response).reduce((accumulator, [key, value]) => {
      const isDateValue = this.entityTransformToDateFields.includes(key);
      const dateValue =
        isDateValue && !!value ? new Date(value as string) : null;

      return {
        ...accumulator,
        [key]: isDateValue ? dateValue : value,
      };
    }, {});
  }

  protected getResourceCallback(response: T): T {
    const transformedResponse = this.transformDefaultModelValues(response);

    this.initialValue = clone(transformedResponse);

    this.setContext(transformedResponse);

    this.stopLoading();

    return transformedResponse;
  }

  protected setContext(transformedResponse: any): void {
    if (transformedResponse && transformedResponse.hasOwnProperty('id')) {
      this.context = {
        [`${this.entity}_id`]: transformedResponse.id,
      };
    }
  }

  protected handleStateChanges(): void {
    this.formStateService.onReset.subscribe(() => {
      this.model = clone(this.initialValue);

      if (this.formRenderer?.form && this.model && Object.keys(this.model)?.length) {
        this.formRenderer.form.patchValue(this.model);
      }
    });

    super.handleStateChanges();
  }
}
