import { Component, Inject, Optional, ViewEncapsulation } from '@angular/core';
import { LeadApiService } from '../../services/lead-api.service';
import { ToastService } from '@capturum/ui/api';
import { TranslateService } from '@ngx-translate/core';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { PollingHandlerService } from '../../services/polling-handler.service';
import { UPLOAD_POLLING } from '@core/provider/polling.provider';

@Component({
  selector: 'app-upload-leads',
  templateUrl: './upload-leads.component.html',
  styleUrls: ['./upload-leads.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UploadLeadsComponent {
  constructor(
    private readonly leadApiService: LeadApiService,
    private readonly dynamicDialogRef: DynamicDialogRef,
    private toastService: ToastService,
    private translateService: TranslateService,
    @Optional()
    @Inject(UPLOAD_POLLING)
    private uploadPollingService: PollingHandlerService,
  ) {
  }

  public loadFile(data: Record<string, any>): void {
    this.leadApiService.uploadLeads(data).subscribe(response => {
      this.uploadPollingService.startPeriodicalPolling(response?.original?.batch_id);

      this.displayToast();

      this.dynamicDialogRef.close();
    });
  }

  private displayToast(): void {
    this.toastService.info(
      this.translateService.instant('LeadPortal.lead.upload-button.title'),
      this.translateService.instant('LeadPortal.lead.upload-button.started.message'),
    );
  }
}
