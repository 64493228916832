<app-table-widget-layout [dependingFilters]="['dates', 'regions']"
                         [tableName]="'LeadPortal.dashboard.widget.leads-to-be-checked.title' | translate"
                         [listIndexMethod]="listIndexMethod"
                         [entityName]="Entity.LEAD"
                         [columnsData]="tableColumns">
  <ng-container headerActions>
    <a class="link" (click)="redirectToLeads()">
      {{ 'LeadPortal.dashboard.widget.lead-status.action.view-all-leads' | translate }}
    </a>
  </ng-container>
</app-table-widget-layout>
