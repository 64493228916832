<div class="form-group row {{ field?.key }}"
     [class.has-error]="showError"
     [class.disabled-form]="formControl.disabled"
     *ngIf="!(to?.hidden)">
  <div class="col" *ngIf="to?.inlineLabel">
    <label *ngIf="to?.translateLabel && to?.hideLabel !== true" [attr.for]="id">
      <span class="label">{{ to?.translateLabel | observablePipe | async }}</span>

      <span *ngIf="to?.required && to?.hideRequiredMarker !== true">*</span>

      <app-form-tooltip *ngIf="to.tooltip" [tooltip]="to.tooltip"></app-form-tooltip>
    </label>
  </div>

  <div class="col">
    <div class="d-flex justify-content-between">
      <label *ngIf="to?.translateLabel && to?.hideLabel !== true && !to?.inlineLabel" [attr.for]="id">
        <span class="label">{{ to?.translateLabel | observablePipe | async }}</span>

        <span *ngIf="to?.required && to?.hideRequiredMarker !== true">*</span>

        <app-form-tooltip *ngIf="to.tooltip" [tooltip]="to.tooltip"></app-form-tooltip>
      </label>

      <label *ngIf="to?.translateLink && !(to?.disabled)">
        <a class="clickable-label" (click)="to?.onClickCallback()">{{ to?.translateLink }}</a>

        <app-form-tooltip *ngIf="to.tooltip" [tooltip]="to.tooltip"></app-form-tooltip>
      </label>
    </div>

    <ng-template #fieldComponent></ng-template>

    <div *ngIf="showError" class="error-message">
      <i class="fa fa-exclamation-triangle ml-0"></i>

      <formly-validation-message [field]="field"></formly-validation-message>
    </div>

    <small *ngIf="to?.description"
           class="form-text text-muted">
      {{ to?.description | observablePipe | async }}
    </small>
  </div>
</div>
