<div class="back-btn text-left mb-2 d-flex align-items-center">
  <a [routerLink]="url" class="d-flex align-center">
    <i class="fal fa-angle-left"></i>

    <ng-container *ngIf="text; else defaultText;">
      {{ text | observablePipe | async }}
    </ng-container>

    <ng-template #defaultText>
      {{ 'button.back-to-overview' | translate }}
    </ng-template>
  </a>
</div>
