import { Injectable } from '@angular/core';
import { ApiHttpService, ApiService } from '@capturum/api';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class UserAgreementService extends ApiService<any> {
  protected endpoint = 'agree-collaboration';

  constructor(private api: ApiHttpService) {
    super(api);
  }

  public agree(): Observable<void> {
    return this.apiHttp.put(`/${this.endpoint}`, { is_accepted: true });
  }
}
