import { Injectable, Injector } from '@angular/core';

@Injectable()
export class AppInjector {
  /* tslint:disable:member-ordering */
  private static injector: Injector;

  public static setInjector(injector: Injector): void {
    AppInjector.injector = injector;
  }

  public static getInjector(): Injector {
    return AppInjector.injector;
  }
}
