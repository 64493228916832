import { Component } from '@angular/core';
import { UserAgreementService } from '@features/user-agreement/services/user-agreement.service';
import { Router } from '@angular/router';
import { AppRoutes } from '@core/enums/routes.enum';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from '@core/services/confirmation.service';
import { ToastService } from '@capturum/ui/api';
import { UserService } from '../../../user/services/user.service';
import { of } from 'rxjs';
import { delay, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-user-agreement',
  templateUrl: './user-agreement.component.html',
  styleUrls: ['./user-agreement.component.scss'],
})
export class UserAgreementComponent {
  public checked: boolean;

  constructor(
    private userAgreementService: UserAgreementService,
    private router: Router,
    protected readonly userService: UserService,
    protected readonly translateService: TranslateService,
    protected readonly confirmationService: ConfirmationService,
    protected readonly toastService: ToastService
  ) {}

  public submit(): void {
    if (this.checked) {
      const user = JSON.parse(localStorage.getItem('user'));

      this.userAgreementService
        .agree()
        .pipe(
          switchMap(() => {
            if (!user?.installer_active) {
              return of(true).pipe(
                delay(2000),
                tap(() => {
                  this.confirmationService.confirm({
                    header: this.translateService.instant(
                      'builders.confirmation.title'
                    ),
                    message: this.translateService.instant(
                      'LeadPortal.user.confirm-activation.message'
                    ),
                    accept: () => {
                      this.userService
                        .activateUser(user.id)
                        .pipe(
                          tap(() => {
                            this.toastService.success(
                              this.translateService.instant(
                                'toast.success.title'
                              ),
                              this.translateService.instant(
                                'LeadPortal.user.activation.toast.message'
                              )
                            );
                          })
                        )
                        .subscribe();
                    },
                  });
                })
              );
            } else {
              return of(null);
            }
          })
        )
        .subscribe(() => {
          this.router.navigate([AppRoutes.dashboard]);
        });
    }
  }
}
