import { Observable } from 'rxjs';
import { NgxPermissionsObject } from 'ngx-permissions';

export interface HasOptionalRequiredPermission {
  requiredPermission?: string; // @TODO: Add support for string[]
}

/**
 * Use to filter out objects without the required permission.
 *
 * Example:
 *    this.ngxPermissionsService.permissions$.pipe(
 *       filterRequiredPermission(items),
 *    ).subscribe((items: T[]) => {})
 *
 * @param items: An array of objects that contain the (optional) key: requiredPermission
 */
export const filterRequiredPermission = <
  T extends HasOptionalRequiredPermission
>(
  items: T[]
) => {
  return (source): Observable<T[]> => {
    return new Observable((subscriber) => {
      source.subscribe({
        next(permissionObject: NgxPermissionsObject): void {
          const permissions = Object.keys(permissionObject);
          const filteredItems = items.filter((item) => {
            return item.requiredPermission
              ? permissions.includes(item.requiredPermission)
              : true; // Don't filter if no permissions are required
          });

          subscriber.next(filteredItems);
        },
        error(error: any): void {
          subscriber.error(error);
        },
        complete(): void {
          subscriber.complete();
        },
      });
    });
  };
};
