import { Injectable } from '@angular/core';
import { FilterMetadata } from 'primeng/api';
import { BehaviorSubject, Observable } from 'rxjs';
import { FilterService } from '@shared/modules/filters/classes/abstract-filter-service.class';
import { FilterConfig } from '../models/filter-config.model';
import { FilterRequest } from '../models/filter-request.model';
import { DateConverterUtil, DateUtilConfig } from '@core/utils/date-converter.util';
import { FilterTypeConverterUtil } from '@shared/modules/filters/utils/filter-type-converter.util';
import { debounceTime } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class BaseFilterService extends FilterService {
  public activeFilters: Record<string, FilterMetadata> = {};

  private _filters: BehaviorSubject<Record<string, FilterMetadata>> =
    new BehaviorSubject<Record<string, FilterMetadata>>({});
  private _filterConfig: BehaviorSubject<FilterConfig[]> = new BehaviorSubject<
    FilterConfig[]
  >([]);
  private _searchFilter: BehaviorSubject<string> = new BehaviorSubject<string>(
    null
  );

  public onClear(): Observable<Record<string, FilterMetadata>> {
    return this._filters.asObservable();
  }

  public onFilterChange(): Observable<Record<string, FilterMetadata>> {
    return this._filters.asObservable().pipe(debounceTime(500));
  }

  public addFilter(filter: FilterRequest): void {
    this.activeFilters = {
      ...this.activeFilters,
      ...FilterTypeConverterUtil.toRecord(this.transformFilterValues(filter)),
    };

    this._filters.next(this.activeFilters);
  }

  public removeFilter(field: string): void {
    if (this.activeFilters.hasOwnProperty(field)) {
      delete this.activeFilters[field];
    }
  }

  public resetFilters(): void {
    this.activeFilters = {};

    this._filters.next({});
    this._searchFilter.next(null);
  }

  public onFilterConfig(): Observable<FilterConfig[]> {
    return this._filterConfig.asObservable();
  }

  public setFilterConfig(config: FilterConfig[]): void {
    this._filterConfig.next(config);
  }

  public onSearch(): Observable<string> {
    return this._searchFilter.asObservable();
  }

  public setSearch(value: string): void {
    this._searchFilter.next(value);
  }

  public getFilterConfiguration(): FilterConfig[] {
    return [];
  }

  protected transformFilterValues(filter: FilterRequest): FilterRequest {
    let filterValue = filter.value;

    if (Array.isArray(filterValue)) {
      if (filterValue.length === 2 && DateConverterUtil.isDate(filterValue[0]) && DateConverterUtil.isDate(filterValue[1])) {
        filterValue = [
          DateConverterUtil.fromDateToTime(new Date(filterValue[0]), DateUtilConfig.startOfDay),
          DateConverterUtil.fromDateToTime(new Date(filterValue[1]), DateUtilConfig.endOfDay),
        ];
      } else {
        filterValue = filterValue.map(value => {
          return this.transformFilterValue(value);
        });
      }
    } else {
      filterValue = this.transformFilterValue(filter.value);
    }

    return {
      ...filter,
      value: filterValue,
    };
  }

  protected transformFilterValue(value: any): any {
    if (DateConverterUtil.isDate(value)) {
      return new Date(value);
    }

    return value;
  }
}
