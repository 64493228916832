import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Tenant } from '@core/enums/tenant.enum';
import { TranslateService } from '@ngx-translate/core';
import { BaseFilterService } from '@shared/modules/filters/services/base-filter.service';
import { AuthService } from '@core/services/auth.service';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FilterTypeConverterUtil } from '@shared/modules/filters/utils/filter-type-converter.util';
import { User } from '../../../user/models/user.model';
import { UIChart } from 'primeng/chart';
import { DashboardService } from '../../services/dashboard.service';
import { AppRoutes } from '@core/enums/routes.enum';
import { UTMAnswer } from '../../../leads/models/utm-answer.enum';
import { UtmAnswerConfigDataModel } from '../../models/utm-answer-config-data.model';
import { UtmAnswersOverview } from '../../models/utm-answers-overview.model';
import { LegendUtmAnswersItem } from '../../models/legend-utm-answers-item.model';

@Component({
  selector: 'app-utm-chart',
  templateUrl: './utm-chart.component.html',
  styleUrls: ['./utm-chart.component.scss'],
})
export class UtmChartComponent implements OnInit, OnDestroy {
  @ViewChild('chart')
  public UIChart: UIChart;

  public data: any;
  public chartOptions: any;
  public utmAnswers: UtmAnswersOverview;
  public utmAnswersConfig: UtmAnswerConfigDataModel;
  public AppRoutes = AppRoutes;
  public legendItems: LegendUtmAnswersItem[];

  private destroy$ = new Subject();

  private tenantColorMap = {
    [Tenant.HEATPUMPS]: {
      [UTMAnswer.DIRECT]: '#800031',
      [UTMAnswer.FACEBOOK]: '#b40f45',
      [UTMAnswer.LG]: '#d64750',
      [UTMAnswer.GOOGLE]: '#f4725a',
      [UTMAnswer.FB_MESSENGER]: '#fca57b',
      [UTMAnswer.INSTAGRAM]: '#b40f45',
      [UTMAnswer.KIESKEURIG]: '#d0472e',
      [UTMAnswer.LGAIRCOKOPEN]: '#44110d',
    },
    [Tenant.AIRCONDITIONING]: {
      [UTMAnswer.DIRECT]: '#036273',
      [UTMAnswer.FACEBOOK]: '#0096b4',
      [UTMAnswer.LG]: '#0dccd9',
      [UTMAnswer.GOOGLE]: '#189eb6',
      [UTMAnswer.FB_MESSENGER]: '#40ffef',
      [UTMAnswer.INSTAGRAM]: '#009396',
      [UTMAnswer.KIESKEURIG]: '#00d7ff',
      [UTMAnswer.LGAIRCOKOPEN]: '#053f3f',
    },
  };

  constructor(
    private readonly dashboardService: DashboardService,
    private readonly translateService: TranslateService,
    private readonly filterService: BaseFilterService,
    private readonly authService: AuthService
  ) {}

  public ngOnInit(): void {
    this.filterService
      .onFilterChange()
      .pipe(
        switchMap((filters) => {
          return this.dashboardService.getUtmAnswerData({
            filters: FilterTypeConverterUtil.toFilterOptions(filters),
          });
        }),
        takeUntil(this.destroy$)
      )
      .subscribe((utmAnswers) => {
        this.utmAnswers = utmAnswers;

        this.initializeChart();
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public initializeChart(): void {
    const currentUser: User = this.authService.getUser();

    this.utmAnswersConfig = {
      [this.translateService.instant('LeadPortal.lead.utm.answer.direct')]: {
        nrOfAnswers: this.utmAnswers?.utms?.direct?.count,
        percentage: this.utmAnswers?.utms?.direct?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndAnswer(
          currentUser?.tenant_name,
          UTMAnswer.DIRECT
        ),
      },
      [this.translateService.instant('LeadPortal.lead.utm.answer.facebook')]: {
        nrOfAnswers: this.utmAnswers?.utms?.fb?.count,
        percentage: this.utmAnswers?.utms?.fb?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndAnswer(
          currentUser?.tenant_name,
          UTMAnswer.FACEBOOK
        ),
      },
      [this.translateService.instant('LeadPortal.lead.utm.answer.lg')]: {
        nrOfAnswers: this.utmAnswers?.utms?.lg?.count,
        percentage: this.utmAnswers?.utms?.lg?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndAnswer(
          currentUser?.tenant_name,
          UTMAnswer.LG
        ),
      },
      [this.translateService.instant('LeadPortal.lead.utm.answer.google')]: {
        nrOfAnswers: this.utmAnswers?.utms?.google?.count,
        percentage: this.utmAnswers?.utms?.google?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndAnswer(
          currentUser?.tenant_name,
          UTMAnswer.GOOGLE
        ),
      },
      [this.translateService.instant(
        'LeadPortal.lead.utm.answer.fb_messenger'
      )]: {
        nrOfAnswers: this.utmAnswers?.utms?.msg?.count,
        percentage: this.utmAnswers?.utms?.msg?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndAnswer(
          currentUser?.tenant_name,
          UTMAnswer.FB_MESSENGER
        ),
      },
      [this.translateService.instant('LeadPortal.lead.utm.answer.instagram')]: {
        nrOfAnswers: this.utmAnswers?.utms?.ig?.count,
        percentage: this.utmAnswers?.utms?.ig?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndAnswer(
          currentUser?.tenant_name,
          UTMAnswer.INSTAGRAM
        ),
      },
      [this.translateService.instant('LeadPortal.lead.utm.answer.kieskeurig')]:
        {
          nrOfAnswers: this.utmAnswers?.utms?.kieskeurig?.count,
          percentage: this.utmAnswers?.utms?.kieskeurig?.percentage,
          backgroundColor: this.getBackgroundColorByTenantAndAnswer(
            currentUser?.tenant_name,
            UTMAnswer.KIESKEURIG
          ),
        },
      [this.translateService.instant(
        'LeadPortal.lead.utm.answer.lgaircokopen'
      )]: {
        nrOfAnswers: this.utmAnswers?.utms?.lgaircokopen?.count,
        percentage: this.utmAnswers?.utms?.lgaircokopen?.percentage,
        backgroundColor: this.getBackgroundColorByTenantAndAnswer(
          currentUser?.tenant_name,
          UTMAnswer.LGAIRCOKOPEN
        ),
      },
    };

    this.legendItems = Object.entries(this.utmAnswersConfig).map(
      ([key, value]) => {
        return {
          backgroundColor: value.backgroundColor,
          nrOfAnswers: value.nrOfAnswers,
          name: key,
        };
      }
    );

    this.data = {
      labels: [...Object.keys(this.utmAnswersConfig)],
      legend: {
        position: 'right',
        align: 'middle',
      },
      datasets: [
        {
          data: this.legendItems.map((item) => {
            return item.nrOfAnswers;
          }),
          backgroundColor: this.legendItems.map((item) => {
            return item.backgroundColor;
          }),
          ...this.utmAnswersConfig,
        },
      ],
    };

    this.chartOptions = {
      plugins: {
        legend: {
          display: false,
        },
        tooltip: {
          enabled: false,
          external: ({ tooltip }) => {
            const chart = this.UIChart.chart;

            const tooltipElRef = this.getOrCreateTooltip();

            if (tooltip.opacity === 0) {
              tooltipElRef.style.opacity = '0';

              return;
            }

            tooltipElRef.classList.remove('above', 'below', 'no-transform');
            tooltipElRef.classList.add('center');

            tooltipElRef.innerHTML = this.getTooltipInnerHtml(tooltip);

            this.setTooltipCustomPosition(tooltipElRef, tooltip, chart);
          },
        },
      },
      responsive: false,
    };
  }

  private getBackgroundColorByTenantAndAnswer(
    tenantName: Tenant,
    answer: UTMAnswer
  ): string {
    const tenantSetting = this.tenantColorMap[tenantName];

    return tenantSetting?.[answer];
  }

  private getOrCreateTooltip(): HTMLElement {
    let tooltipElRef = document.getElementById('chartjs-tooltip');

    if (!tooltipElRef) {
      tooltipElRef = document.createElement('div');
      tooltipElRef.id = 'chartjs-tooltip';
      tooltipElRef.style.position = 'absolute';
      tooltipElRef.style.pointerEvents = 'none';

      const table = document.createElement('table');

      table.style.margin = '0px';

      tooltipElRef.appendChild(table);
      document.body.appendChild(tooltipElRef);
    }

    return tooltipElRef;
  }

  private getTooltipInnerHtml(tooltip: any): string {
    const title = tooltip?.body[0]?.lines[0].split(':')[0];

    const {
      nrOfAnswers,
      percentage,
    }: { nrOfAnswers: number; percentage: number } =
      this.utmAnswersConfig[title];

    if (!nrOfAnswers || !percentage) {
      return '';
    }

    const nrOfAnswersLabel = this.translateService.instant(
      'LeadPortal.dashboard.widget.lead-status.number-of-answers'
    );
    const percentageLabel = this.translateService.instant(
      'LeadPortal.dashboard.widget.lead-status.percentage'
    );

    return `
        <div class="tooltip-chartjs">
          <span class="title">${title}</span>
          <div class="body">
            <div class="info lead">
              <span>${nrOfAnswersLabel}</span>
              <span>${nrOfAnswers}</span>
            </div>
            <div class="info percentage">
              <span>${percentageLabel}</span>
              <span>${percentage.toFixed(2)}%</span>
            </div>
          </div>
        </div>
    `;
  }

  private setTooltipCustomPosition(
    tooltipElRef: HTMLElement,
    tooltip: any,
    chart: any
  ): void {
    const position = chart.canvas.getBoundingClientRect();

    tooltipElRef.style.left =
      position.left + window.pageXOffset + tooltip.caretX - 100 + 'px';
    tooltipElRef.style.top =
      position.top + window.pageYOffset + tooltip.caretY + 'px';
    tooltipElRef.style.opacity = '1';
    tooltipElRef.style.fontSize = '14px';
    tooltipElRef.style.fontFamily = 'LGEITextTTF';
  }
}
