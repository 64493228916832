<div class="upload-container">
  <cap-file-upload accept=".pdf"
                   [ngClass]="{'disabled': to?.disabled || to?.QuotePdf}"
                   [multiple]="false"
                   (onFileChange)="loadFile({ file: $event[0] }, to?.leadId)">
  </cap-file-upload>

  <cap-button [disabled]="!to?.QuotePdf" class="download-button" (onClick)="downloadFile(to?.leadId)">
    <span class="fas fa-download"></span>
  </cap-button>
  <cap-button [disabled]="to?.disabled || !to?.QuotePdf" class="remove-button" (onClick)="removeFile(to?.leadId)">
    <span class="fas fa-trash"></span>
  </cap-button>
</div>

