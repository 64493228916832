import { Component, OnInit } from '@angular/core';
import { InfoTableColumn, InfoTableColumnType } from '@capturum/ui/info-table';
import { AppRoutes } from '@core/enums/routes.enum';
import { ListIndexMethod } from '@features/dashboard/models/list-index-method.enum';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { LeadStatus } from '@features/leads/models/lead-status.enum';
import { ListRendererUtils } from '@capturum/builders/list-renderer';
import { BaseDataService } from '@core/services/base-data.service';
import { BaseDataKey } from '@core/enums/base-data-key.enum';
import { first } from 'rxjs/operators';
import { Entity } from '@core/enums/entity.enum';

@Component({
  selector: 'app-leads-to-be-checked',
  templateUrl: './leads-to-be-checked.component.html',
  styleUrls: ['./leads-to-be-checked.component.scss'],
})
export class LeadsToBeCheckedComponent implements OnInit {
  public tableColumns: InfoTableColumn[];

  public listIndexMethod = ListIndexMethod.LeadsToBeChecked;

  public AppRoutes = AppRoutes;

  public Entity = Entity;

  constructor(
    private readonly translateService: TranslateService,
    private readonly router: Router,
    private readonly baseDataService: BaseDataService
  ) {}

  public ngOnInit(): void {
    this.tableColumns = [
      {
        field: 'customer_type',
        title: this.translateService.instant(
          'LeadPortal.dashboard.widget.customer-type.label'
        ),
        type: InfoTableColumnType.Template,
        disableSort: true,
      },
      {
        field: 'name',
        title: this.translateService.instant(
          'LeadPortal.dashboard.widget.name.label'
        ),
        disableSort: true,
      },
      {
        field: 'zip_code',
        title: this.translateService.instant(
          'LeadPortal.distributor.zipcode.label'
        ),
        disableSort: true,
      },
      {
        field: 'city',
        title: this.translateService.instant(
          'LeadPortal.distributor.city.label'
        ),
        disableSort: true,
      },
    ];
  }

  public redirectToLeads(): void {
    this.baseDataService
      .findByValue(BaseDataKey.LEAD_STATUS, LeadStatus.Check)
      .pipe(first())
      .subscribe((baseDataValue) => {
        if (baseDataValue) {
          this.router.navigate([AppRoutes.lead], {
            queryParams: ListRendererUtils.generateQueryUrlFilters({
              status_base_data_value_id: [baseDataValue.id],
            }),
            queryParamsHandling: 'merge',
          });
        }
      });
  }
}
